import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"
import { Carousel, CarouselItem } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

function SlideShow() {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "salon" } }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    `
  )
  return (
    <Carousel>
      {allFile.edges.map((item, index) =>(
          <CarouselItem key={index}>
              <Img style={{
                width: `900px`,
                height: `400px`,
            }} fluid={item.node.childImageSharp.fluid}/>
          </CarouselItem>
      ))}
    </Carousel>
  )
}

export default SlideShow