import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles(theme=>({
  root: {
    background: theme.palette.primary,
    '& > *': {
      borderBottom: 'unset',
    },
    '&:hover':{
        cursor: 'pointer'
    }
  },
  tableBackground:{
      background: theme.palette.primary.main
  },
  secondaryBackground:{
      background: '#fffcea'
  }
}));

const rows = [
    {
        name: 'Ladies',
        services:[
            {
                service: 'Cut and finish',
                price: '35.00'
            },
            {
                service: 'Wash and blow',
                price: '17.50'
            },
            {
                service: 'Cut only',
                price: '17.50'
            },
            {
                service: 'Hair up',
                price: '40.00'
            }
        ]
    },
    {
        name: 'Children (under 16)',
        services:[
            {
                service: 'Cut and finish',
                price: '26.00'
            },
            {
                service: 'Cut only primary',
                price: '9.00'
            },
            {
                service: 'Cut only seconday',
                price: '12.50'
            }
        ]
    },
    {
        name: 'Gents',
        services:[
            {
                service: 'Cut only',
                price: '12.50'
            },
            {
                service: 'Wash and cut',
                price: '16.00'
            }
        ]
    },
    {
        name: 'Colouring',
        services:[
            {
                service: 'Full colour from',
                price: '48.00'
            },
            {
                service: 'Regrowth from',
                price: '40.00'
            },
            {
                service: 'Full head foil from',
                price: '64.00'
            },
            {
                service: 'Half head foils from',
                price: '53.00'
            },
            {
                service: 'T Section from',
                price: '43.00'
            },
            {
                service: 'Toner from',
                price: '12.50'
            },
            {
                service: 'Colour correction and fashion colours',
                price: 'Price on consultation'
            }
        ]
    },
    {
        name: 'Perming',
        services:[
            {
                service: 'Perming',
                price: 'from 47.00'
            }
        ]
    },
    {
        name: 'Special Services',
        services:[
            {
                service: 'Wedding',
                price: 'FREE consultation'
            },
            {
                service: 'Bridal hair',
                price: 'from 40.00'
            }
        ],
        notes: 'Please call the salon to discuss your tailor-made Wedding Hair Package'
    },
    // {
    //     name: 'Nail Treatments',
    //     services:[
    //         {
    //             service: 'Gell Nails',
    //             price: '17.00'
    //         },
    //         {
    //             service: 'Gell Nails with added strength',
    //             price: '22.00'
    //         },
    //         {
    //             service: 'Dip Powder Nails',
    //             price: '25.00'
    //         },
    //         {
    //             service: 'Dip Powder Nails With Tips',
    //             price: '30.00'
    //         },
    //         {
    //             service: 'Gell Toe Nails',
    //             price: '17.00'
    //         },
    //         {
    //             service: 'Soak off',
    //             price: '7.50'
    //         },
    //         {
    //             service: 'Mini Manicure',
    //             price: '10.00'
    //         },
    //         {
    //             service: 'Nail Repair from',
    //             price: '2.50'
    //         }
    //     ]
    // },
];

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root} onClick={() => setOpen(!open)}>
        <TableCell>
          <IconButton color="secondary" aria-label="expand row" size="small" >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
            <Typography variant='h4' align='left' color="secondary">
                {row.name}
            </Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.secondaryBackground}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                        <Typography variant='h5' align='left' color="primary">
                            <strong>Service</strong>
                        </Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant='h5' align='center' color="primary">
                            <strong>Price (£)</strong>
                        </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.services.map((serivceRow) => (
                    <TableRow key={serivceRow.service} >
                      <TableCell component="th" scope="row">
                          <Typography variant='subtitle2' align='left' color="primary">
                            {serivceRow.service}
                          </Typography>
                      </TableCell>
                      <TableCell align="center">
                          <Typography variant='subtitle2' align='center' color="primary">
                            {serivceRow.price}
                          </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                  {row.notes && 
                    <TableRow>
                        <TableCell component="th" scope="row" colSpan={2}>
                            <Typography variant='subtitle2' align='center' color="primary">
                                {row.notes}
                            </Typography>
                        </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    services: PropTypes.arrayOf(
      PropTypes.shape({
        price: PropTypes.string.isRequired,
        service: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    notes: PropTypes.string,
  }).isRequired,
};

const CollapsibleTable = () => {
    const classes = useRowStyles();
    return (
        <TableContainer component={Paper} className={classes.tableBackground}>
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                <TableCell />
                <TableCell style={{width:'100%'}}>
                    <Typography variant='h3' align='left' color="secondary">
                        Categories
                    </Typography>
                </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <Row key={row.name} row={row} />
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    );
}

export default CollapsibleTable