import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import WelcomeSection from '../components/welcome'
import AboutUs from '../components/about-us'
import Book from '../components/book'
import Services from '../components/services'
import Team from '../components/meet-the-team'
import Work from '../components/our-work'
import Salon from '../components/our-salon'

const IndexPage = () => {
  return(
    <Layout>
      <Seo title="Home"/>
        <WelcomeSection/>
        <AboutUs/>
        <Book/>
        <Services/>
        <Team/>
        <Work/>
        <Salon/> 
    </Layout>
  )
}

export default IndexPage
