import React from "react"
import { Typography } from '@material-ui/core'
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TeamBox from './team-box'
import { graphql, StaticQuery } from 'gatsby'

const useStyles = makeStyles(theme => ({
  containerGrid:{
    flexGrow: 1,
    marginTop: '50px'
  },
  grid:{
    minHeight: '50vh'
  },
  divider:{
      width: '50px',
      margin: '0 auto',
      background: '#ACFFB9',
      height: '0.1rem'
  }
}));

const team_data = [
    {
        name: 'Claire',
        role: `Salon Director`,
        image: 'claire.jpeg'
    },
    {
        name: 'sheelagh',
        role: `Stylist`,
        image: 'sheelagh.jpeg'
    },
    {
        name: 'josie',
        role: `Stylist`,
        image: 'josie.jpeg'
    },
    // {
    //     name: 'sally',
    //     role: `Nail Technician And Salon Wingman`,
    //     image: 'sally.jpeg'
    // }
]

const Services = () => {
    const classes = useStyles();
    return (
        <StaticQuery
        query={graphql`
            query {
                images: allFile(filter: {relativeDirectory: {eq: "team"}}) {
                edges {
                    node {
                        base
                        childImageSharp {
                            fluid(maxWidth: 265) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                }
            }
        `}
        render={data => {
            return(
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    className={classes.grid}
                >
                        <Typography variant='h2' align='center' color="primary" style={{margin: `1.45rem`, paddingTop: `2.9rem` }}>
                            Meet The Team
                            <Divider className={classes.divider}/>
                        </Typography>
                        <Grid 
                            container
                            className={classes.containerGrid}
                            direction="row"
                            alignItems="center"
                            justify="space-evenly"
                            spacing={0}
                        >
                            {team_data.map((item,index)=>(
                                <TeamBox 
                                    data={{
                                        ...item,
                                        image: data.images.edges.find(n => n.node.base === item.image)
                                    }}
                                    key={index}
                                />
                            ))}
                    </Grid>
                </Grid>
            )
            }}
    />
    )
}

export default Services