import React from "react"
import { Typography } from '@material-ui/core'
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WorkCarousel from './work-carousel'

const useStyles = makeStyles(theme => ({
  grid:{
    background: theme.palette.grey[100],
    minHeight: '50vh'
  },
  containerGrid:{

    marginTop: '50px',
    marginBottom: '50px'
  },
  divider:{
      width: '50px',
      margin: '0 auto',
      background: '#ACFFB9',
      height: '0.1rem'
  }
}));


const Salon = () => {
    const classes = useStyles();
            return(
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    className={classes.grid}
                >
                    <Typography variant='h2' align='center' color="primary" style={{margin: `1.45rem`, paddingTop: `2.9rem` }}>
                        Our Salon
                        <Divider className={classes.divider}/>
                    </Typography>
                    <Typography variant='subtitle2' align='center' color="primary" style={{margin: `1.45rem` }}>
                        New style, same great service
                    </Typography>
                    <Grid 
                        item
                        xs={12} md={6}
                        className={classes.containerGrid}
                    >
                        <WorkCarousel/>
                    </Grid>
                </Grid>
            )
}

export default Salon