import React from "react"
import { Typography } from '@material-ui/core'
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WelcomeBox from './welcome-box'
import { graphql, StaticQuery } from 'gatsby'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  },
  containerGrid:{
    flexGrow: 1,
  },
  divider:{
      width: '50px',
      margin: '0 auto',
      background: '#ACFFB9',
      height: '0.1rem'
  }
}));

const welcome_data = [
    {
        title: 'Hair cuts',
        text: `With a minimum of ten years experience all our stylists 
        are qualified to the highest standard in order 
        to achieve a fabulous long lasting look`,
        more: '',
        more_icon: '',
        image: 'hair.jpg'
    },
    {
        title: 'Hair colouring',
        text: `We are an Alfaparf Milano specialist salon. We pride our colours
        in being vibrant, long lasting, conditioning and is one of the 
        lowest ammonia and ppd free colouring systems in the hairdressing market`,
        more: '',
        more_icon: '',
        image: 'colour.jpg'
    },
    {
        title: 'wedding hair',
        text: `For your special day, visit us at the Fanny & Clara salon
        for your bespoke wedding hair consultation`,
        more: '',
        more_icon: '',
        image: 'wedding.jpg'
    },
    // {
    //     title: 'nails',
    //     text: `Our experienced mail technician offers a 
    //     full range of nail services including; gel by Cuccio, dip powder 
    //     by NuGenesis and extensions`,
    //     more: '',
    //     image: 'nails.jpg'
    // }
]

const WelcomeSection = () => {
    const classes = useStyles();
    return (
        <StaticQuery
        query={graphql`
            query {
                images: allFile(filter: {relativeDirectory: {eq: "welcome"}}) {
                edges {
                    node {
                        base
                        childImageSharp {
                            fluid(maxWidth: 265) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                }
            }
        `}
        render={data => {
            return(
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="flex-start"
                    justify="space-evenly"
                    className={classes.containerGrid}
                >
                    <div className={classes.root}>
                        <Typography variant='h2' align='center' color="primary" style={{margin: `1.45rem`, paddingTop: `2.9rem` }}>
                            Welcome To Fanny and Clara's
                            <Divider className={classes.divider}/>
                        </Typography>
                        <Typography variant='subtitle2' align='center' color="primary" style={{margin: `1.45rem` }}>
                            Hairdressing in the Hope Valley
                        </Typography>
                        <Grid 
                            container
                            className={classes.containerGrid}
                            direction="row"
                            alignItems="flex-start"
                            justify="space-evenly"
                            spacing={1}
                        >
                            {welcome_data.map((item,index)=>(

                                <WelcomeBox 
                                data={{
                                    ...item,
                                    image: data.images.edges.find(n => n.node.base === item.image)
                                }}
                                key={index}
                                />
                            ))}
                    </Grid>
                    </div>
                </Grid>
            )
            }}
    />
    )
}

export default WelcomeSection
