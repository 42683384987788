import React, { useRef  } from "react"
import { Typography } from '@material-ui/core'
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTransition, animated, config, useChain } from 'react-spring'

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
        }
      },
    grid:{
        minHeight: '50vh',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: theme.spacing(2),
        }
    },
    divider:{
        width: '50px',
        margin: '0 auto',
        background: '#ACFFB9',
        height: '0.1rem'
    }
}));

const philosophy_text = `Here at F&C our aim is to offer you the service you've always wanted.
We pride ourselves on offering exceptional hairdressing along with outstanding customer service.
We offer an array of different services including: cuts blow drys, wedding hair, colours, conditioning treatments, flip in hair extensions and much much more.`


const WelcomeSection = (props) => {
    const classes = useStyles();
    
    const items = [
        {
            key: 1,
            element: <Typography variant='h2' align='center' color="primary" style={{margin: `1.45rem`, paddingTop: `2.9rem` }}>
                        About Us
                        <Divider className={classes.divider}/>
                    </Typography>
        },
        {
            key: 2,
            element: <Typography variant='subtitle1' align='center' color="primary" style={{margin: `1.45rem` }}>
                        Our Philosphy
                    </Typography>
        },
        {
            key: 3,
            element: <Typography variant='subtitle1' align='center' color="primary" style={{margin: `1.45rem` }}>
                        {philosophy_text}
                    </Typography>
        }
        ]
    
        const textRef = useRef()
        const transitions = useTransition(items, item => item.key, {
            ref: textRef,
            config: config.molasses,
            unique: true,
            trail: 800 ,
            initial: { opacity: 0,transform: 'translate3d(0, -50%,0)' },
            from: { opacity: 0,  transform: 'translate3d(0,-50%,0)' },
            enter: { opacity: 1,  transform: 'translate3d(0%, 0%,0)' },
            leave: {  opacity: 0, transform: 'translate3d(0%,-50%,0)' }
        })
    

    
        useChain( [textRef] , [1, 5.5])

    return(
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            className={classes.grid}
        >
            <Grid item xs={12} md={9} lg={8}>
                <div className={classes.root}>
                    {transitions.map(({ item, props, key }) =>
                        <animated.div 
                        key={key} 
                        style={props}>
                        {item.element}
                        </animated.div>
                    )}
                </div>
            </Grid>
        </Grid>
    )
            
}

export default WelcomeSection
