import React from "react"
import { Typography } from '@material-ui/core'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Img from "gatsby-image"
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    
  },
  helper:{
    color: theme.palette.primary.light,
    fontWeight: 400
  }
}));

const WelcomeBox = ({data}) => {
  const classes = useStyles();
  const icon = {
    insta_icon: faInstagram
}
    return (
      <Grid
        item xs={12} sm={4} lg={2}
      >
        <Grid 
          className={classes.root}
          direction="column"
          alignItems="center"
          justify="flex-start"
          spacing={0}
          container
        >
          <Grid item>
            <Img style={{
              width: `200px`,
              height: `200px`,
              borderRadius: '50%',
              marginBottom:'10px'
            }} fluid={data.image.node.childImageSharp.fluid} />
          </Grid>
          <Grid item>
            <Typography variant='h4' align='center' color="primary">
              {data.name.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h5' align='center' color="primary">
              {data.role}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' align='center' className={classes.helper} style={{cursor: 'pointer'}}>
              {data.insta || ''} {(data.insta && data.insta_icon && <FontAwesomeIcon icon={icon[data.insta_icon]} />) || ''}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
}

export default WelcomeBox
