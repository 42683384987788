import React from "react"
import { Typography } from '@material-ui/core'
import { Grid, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from './table'

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
        }
      },
  grid:{
    background: theme.palette.grey[100],
    minHeight: '50vh'
  },
  containerGrid:{
    flexGrow: 1,
    marginTop: '50px',
    marginBottom: '50px'
  },
  divider:{
      width: '50px',
      margin: '0 auto',
      background: '#ACFFB9',
      height: '0.1rem'
  }
}));

const Services = () => {
    const classes = useStyles();
    return(
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            className={classes.grid}
        >
            <Typography variant='h2' align='center' color="primary" style={{margin: `1.45rem`, paddingTop: `2.9rem` }}>
                Our Services
                <Divider className={classes.divider}/>
            </Typography>
            <Grid 
                container
                className={classes.containerGrid}
                direction="row"
                alignItems="center"
                justify="space-evenly"
                spacing={0}
            >
                <Grid
                item
                xs={12}
                md={9}
                lg={6}
                >
                    <Table/>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Services