import React from "react"
import { Grid} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BackgroundImage from 'gatsby-background-image'
import { graphql, StaticQuery } from 'gatsby'
import Button from './booking-button'

const useStyles = makeStyles(theme => ({
  root: {
    width:'100%',

  },
  grid:{
    width: '100%',
    height: '100%',
    // backgroundColor: 'rgba(46, 63, 97, 0.9)',
  },
  containerGrid:{
    flexGrow: 1
  },
  imageSize:{
    height: `50vh`,
    width: `100vw`,
    [theme.breakpoints.up('md')]: {
        marginLeft: '-15px'
    }
  }
}));


const WelcomeSection = () => {
    const classes = useStyles();
    return (
        <StaticQuery
            query={graphql`
                query {
                    file(relativePath: { eq: "home.jpg" }) {
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 10000,grayscale: true) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            `}
            render={data => {
                const imageData = data.file.childImageSharp.fluid
                return (
                  <BackgroundImage
                    Tag="section"
                    fluid={imageData}
                    className={classes.imageSize}
                  >
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      className={classes.grid}
                    >
                      <div className={classes.root}>
                        <Grid
                          container
                          className={classes.containerGrid}
                          direction="row"
                          alignItems="center"
                          justify="space-evenly"
                          spacing={0}
                        >
                          <Button
                            button={{
                              title: "Book Now",
                              width: "100%",
                              height: "50vh",
                              link: "https://tinyurl.com/mwvvp26",
                            }}
                          />
                        </Grid>
                      </div>
                    </Grid>
                  </BackgroundImage>
                )
            }}
        />
    )
}

export default WelcomeSection
