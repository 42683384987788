import React from "react"
import { Typography } from '@material-ui/core'
import { Grid, GridList, GridListTile, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    root: {
        width:'100%',
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(2),
        }
      },
    divGrid: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '100%',
    },
    divider:{
        width: '50px',
        margin: '0 auto 75px auto',
        background: '#ACFFB9',
        height: '0.1rem'
    },
    listTile:{
        cursor: 'pointer'
    }
}));

let tileData = [
    {
        img: '1.jpg',
        title: '',
        subtitle: '',
    },
    {
        img: '2.jpg',
        title: '',
        subtitle: '',
    },
    {
        img: '3.jpg',
        title: '',
        subtitle: ''
    },
    {
        img: '4.jpg',
        title: '',
        subtitle: ''
    },
    {
        img: '5.jpg',
        title: '',
        subtitle: '',
    },
    {
        img: '6.jpg',
        title: '',
        subtitle: ''
    },
    {
        img: '7.jpg',
        title: '',
        subtitle: '',
    },
    {
        img: '8.jpg',
        title: '',
        subtitle: '',
      },
      {
        img: '9.jpeg',
        title: '',
        subtitle: '',
      },
      {
        img: '10.jpg',
        title: '',
        subtitle: ''
      },
      {
        img: '11.jpeg',
        title: '',
        subtitle: '',
      },
      {
        img: '12.jpg',
        title: '',
        subtitle: '',
      }
  ];
const Services = () => {
    const classes = useStyles();
    const [state, setState] = React.useState({
        photoIndex: 0,
        isOpen: false
    })
    const xl = useMediaQuery(theme => theme.breakpoints.up('xl'))
    const lg = useMediaQuery(theme => theme.breakpoints.up('lg'))
    const md = useMediaQuery(theme => theme.breakpoints.up('md'))
    const sm = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const cols = xl ? 12 : 
                lg ? 12 :
                md ? 6 :
                sm ? 4 : 2
    const rows = xl ? 2 : 
                lg ? 2 :
                md ? 2 : 1
    return (
        <StaticQuery
        query={graphql`
                query {
                    images: allFile(filter: {relativeDirectory: {eq: "work"}}) {
                    edges {
                        node {
                            base
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    }
                }
            `}
            render={data => {
                tileData = tileData.map(d=>{
                    return {
                        ...d,
                        image: data.images.edges.find(n => n.node.base === d.img) && data.images.edges.find(n => n.node.base === d.img).node.childImageSharp.fluid
                    }
                })

                return(
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        className={classes.grid}
                    >
                        <div className={classes.root}>
                            <Typography variant='h2' align='center' color="primary" style={{margin: `1.45rem`, paddingTop: `2.9rem` }}>
                                Our Work
                                <Divider className={classes.divider}/>
                            </Typography>
                            <div className={classes.divGrid}>
                                <GridList cellHeight={180} cols={cols} className={classes.gridList}>
                                    {tileData.map((tile,index) => (
                                        <GridListTile 
                                            className={classes.listTile} 
                                            key={index} 
                                            cols={2} 
                                            rows={rows} 
                                            onClick={()=> setState({ photoIndex: index, isOpen: true})}
                                        >
                                            {
                                                tile.image ?
                                                    <Img style={{
                                                        width: `100%`,
                                                        height: `100%`,
                                                        }} fluid={tile.image} 
                                                    /> : <img src={tile.img} alt={tile.title} />
                                            }
                                        </GridListTile>
                                    ))}
                                </GridList>
                                {state.isOpen && (
                                    <Lightbox
                                        mainSrc={tileData[state.photoIndex].image ? tileData[state.photoIndex].image.src : tileData[state.photoIndex].img}
                                        nextSrc={tileData[(state.photoIndex + 1) % tileData.length].img}
                                        prevSrc={tileData[(state.photoIndex + tileData.length - 1) % tileData.length].img}
                                        onCloseRequest={() => setState({ ...state, isOpen: false })}
                                        onMovePrevRequest={() =>
                                        setState({
                                            ...state,
                                            photoIndex: (state.photoIndex + tileData.length - 1) % tileData.length,
                                        })
                                        }
                                        onMoveNextRequest={() =>
                                        setState({
                                            ...state,
                                            photoIndex: (state.photoIndex + 1) % tileData.length,
                                        })
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </Grid>
                )
            }}
        />
    
    )
}

export default Services

